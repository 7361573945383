<script lang="ts" setup>
import { computed } from "vue"; // Properties

// Properties
const props = defineProps({
    modelValue: {
        type: [String, Number],
        default: "",
    },
    initialError: {
        type: String,
        default: "",
    },
    showTooltip: {
        type: Boolean,
        default: false,
    },
    required: {
        type: Boolean,
        default: false,
    },
    roundedCorners: {
        type: Boolean,
        default: true,
    },
    roundSize: {
        type: String,
        default: "rounded-2xl",
    },
    height: {
        type: String,
        default: "h-9",
    },
});

// Data
const emit = defineEmits(["update:modelValue", "change", "blur"]);
const error = ref("");

const model = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        validateInput(value);
        return emit("update:modelValue", value);
    },
});

// Functions
function validateInput(value: any) {
    error.value = "";
    if (props.required) {
        if (!value || value.length <= 0) {
            error.value = "Field is required";
        }
    }
}

function onChange(event: any) {
    validateInput(event.target.value);
    emit("change", event.target.value, error.value);
}

function onBlur(event: any) {
    validateInput(event.target.value);
    emit("blur", event.target.value, error.value);
}
</script>

<template>
    <div
        v-tooltip="{
            content: initialError,
            shown: showTooltip ? true : false,
            triggers: [],
        }"
        class="relative inline-block border pl-4 pr-3 hover:border-gray-400"
        :class="[
            roundedCorners ? roundSize : '',
            height,
            !initialError ? 'border-gray-300 bg-gray-50' : 'border-red-700 bg-red-50',
        ]"
    >
        <select v-model="model" :required="required" class="select-down-arrow" @change="onChange" @blur="onBlur">
            <slot></slot>
        </select>
    </div>
</template>

<style scoped>
.select-down-arrow {
    appearance: none;
    background-image: url("/images/fa/chevron-down-regular.svg");
    background-size: 12px;
    background-position: bottom 7px right;

    @apply h-full w-full bg-transparent bg-no-repeat focus:outline-none;
}
</style>
